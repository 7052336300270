.chat-container {
  display: flex;
  height: 90%;
  font-family: Arial, sans-serif;
  flex-direction: column;
  padding: 20px;
}

.chat-list {
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}

.chat-create {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.messages {
  max-height: 70vh;
  min-height: 70vh;
  overflow-y: auto;
  margin-bottom: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
}

.message-item {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  max-width: 50%;
  font-size: 14px;
  line-height: 1.4;
  display: inline-block;
}

.message-item.you {
  background-color: #dcf8c6;
  align-self: flex-end;
  text-align: right;
}

.message-item.other {
  background-color: #f1f1f1;
  align-self: flex-start;
  text-align: left;
}

.message-time {
  font-size: 0.8em;
  color: #888;
  margin-top: 5px;
}

.single-check {
  color: gray;
  margin-left: 5px;
}

.double-checks {
  color: green;
  margin-left: 5px;
}

.read-time {
  margin-left: 5px;
  font-size: 0.8em;
  color: gray;
}

.date-separator {
  text-align: center;
  margin: 10px 0;
  font-weight: bold;
  color: #888;
}

.message-input {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 10px 0;
}

.message-input input {
  flex: 1;
  margin-right: 10px;
  box-sizing: border-box;
}

.no-chat-selected {
  text-align: center;
  color: #888;
  font-size: 18px;
}

.image-preview {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  align-self: flex-start;
}

.image-preview img {
  max-width: 40px;
  max-height: 40px;
  border-radius: 4px;
  cursor: pointer;
}

.image-preview .anticon-close-circle {
  position: absolute;
  top: -5px;
  left: -5px;
  font-size: 16px;
  color: red;
  cursor: pointer;
  background-color: white;
  border-radius: 50%;
}
.file-preview {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  padding: 20px; /* Отступы внутри */
  border-radius: 10px; /* Округленные границы */
  background-color: #f1f1f1; /* Цвет фона для лучшей видимости */
}

.file-preview .anticon-close-circle {
  position: absolute;
  top: 5px;
  right: 5px;  /* Иконка закрытия в правом верхнем углу */
  font-size: 16px;
  color: red;
  cursor: pointer;
  background-color: white;
  border-radius: 50%;
}

.file-preview .anticon {
  font-size: 24px; /* Размер иконки файла */
  margin-right: 10px;
}
/* chat.css */

.hide-scrollbar {
  /* Скрываем скроллбар в Firefox */
  scrollbar-width: none;
  /* Скрываем скроллбар в IE/Edge */
  -ms-overflow-style: none;
}

/* Скрываем скроллбар в Chrome, Safari, Opera */
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}
