.hide-scrollbar {
    /* Для Firefox */
    scrollbar-width: none;
    /* Для IE и старого Edge */
    -ms-overflow-style: none;
  }
  
  .hide-scrollbar::-webkit-scrollbar {
    /* Для Chrome, Safari, Opera */
    width: 0px;
    display: none;
  }
  