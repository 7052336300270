body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.demo-logo-vertical {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.ant-layout {
  height: 100%;
}

.ant-layout-sider {
  height: 100%;
}

.ant-layout-content {
  display: flex;
  flex-direction: column;
  min-height: 0;
  height: 100%;
  padding: 0;
}

@media (max-width: 768px) {
  .ant-layout-sider {
    display: none;
  }

  .ant-layout {
    padding-left: 0 !important;
  }
}